import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

// https://www.gatsbyjs.org/docs/working-with-images-in-markdown

import Layout from "../components/layout"
import Navigation from "../components/navigation"
import SEO from "../components/seo"

export default class BlogList extends React.Component {
  render() {
    const posts = this.props.data.allMarkdownRemark.edges
    const { currentPage, numPages } = this.props.pageContext
    const isFirst = currentPage === 1
    const isLast = currentPage === numPages
    const prevPage =
      currentPage - 1 === 1 ? "/blog" : "/blog/" + (currentPage - 1).toString()
    const nextPage = "/blog/" + (currentPage + 1).toString()

    return (
      <Layout>
        <SEO title="Blog" />
        <Navigation isAbsolute={false} currentPage="blog" inverted />

        <div className="bg-vanilla-ice">
          <section className="container container-small py-12 pt-8 lg:py-20 lg:pt-10">
            <h1 className="text-4xl lg:text-5xl leading-none font-display font-bold text-gradient">
              Blog
            </h1>
            <hr className="bg-meteor-500 bg-gradient-l-mulberry-500 h-1 border-0 w-1/5 mt-4 mb-16" />
            {posts.map(({ node }) => {
              let featuredImgFluid =
                node.frontmatter.featuredImage.childImageSharp.fluid
              return (
                <div key={node.id} className="md:flex mb-10 md:mb-8">
                  <div className="md:w-64">
                    <Link to={node.frontmatter.slug}>
                      <div
                        className="md:w-64 bg-center bg-no-repeat bg-cover"
                        style={{ backgroundImage: `url(${featuredImgFluid.src})`, height: 180 }}
                      ></div>
                    </Link>
                  </div>
                  <div className="mt-4 md:mt-0 md:ml-10">
                    <Link
                      to={node.frontmatter.slug}
                      className="text-3xl leading-none font-display font-bold hover:text-mulberry-500"
                    >
                      {node.frontmatter.title}
                    </Link>
                    <div className="mt-2">
                      {node.frontmatter.date} by {node.frontmatter.author}
                    </div>
                    <div className="mt-4 md:mt-6">
                      {node.frontmatter.summary}
                    </div>
                  </div>
                </div>
              )
            })}
            <div className="mt-24">
              {!isFirst && (
                <Link to={prevPage} rel="prev">
                  ← Previous Page
                </Link>
              )}
              {!isLast && (
                <Link to={nextPage} rel="next">
                  Next Page →
                </Link>
              )}
            </div>
          </section>
        </div>
      </Layout>
    )
  }
}
export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          frontmatter {
            title
            slug
            author
            summary
            date(formatString: "MMMM DD, YYYY")
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
